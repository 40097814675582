.home {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  .innovation {
    // background: linear-gradient(to bottom, #0041e8, #0f3fdc);
    background: white;
    color: #1a202c;
    padding: 10px 0;

    .container {
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;

      h2 {
        font-size: 32px;
        margin: 20px 0px 70px 0px;
        font-weight: 600;
      }

      .highlight {
        color:  #0041e8;
      }

      .features {
        display: flex;
        justify-content: center;
        gap: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
      
        .feature {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 10px;
          // opacity: 1;
          // transform: translateY(-50px);
          transition: opacity 0.5s ease, transform 0.5s ease;
          border: 2px solid #86a3e9bd;
          max-width: 90%;
          flex: 1;


          &:hover {
            transform: translateY(-5px);
            box-shadow: 10px 19px 25px 0 rgba(0,0,0,.19)
          }

          .feature-image {
            width: 100%;
            height: 50%;
            display: block;
            border-radius: 8px 8px 0 0;
          }

          .feature-content {
            padding: 20px;

            h3 {
              font-size: 20px;
              font-weight: 600;
              margin: 0px 0 20px 0;
              color: #0041e8;
            }

            p {
              font-size: 16px;
              line-height: 1.6;
              padding-bottom: 10px;
            }
          }
        }

        .feature.visible {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    @media(max-width: 768px) {
      .features {
        gap: 28px !important;
        display: flex !important;
        overflow-x: auto !important;
        overflow-y: auto !important;
        scroll-snap-type: x mandatory; 
      }
      .feature {
        flex: 0 0 90% !important;
        scroll-snap-align: start !important;
        .feature-image {
          height: 250px !important;
        }
      }
      .feature:first-child {
        margin-left: 1000px !important;
      }
      .feature:last-child {
        margin-right: 0 !important;
      }
      .features ::-webkit-scrollbar {
            display: none;
      }
    }

    

    @media (max-width: 1024px) {
      .container {
        padding: 0px 20px 10px 20px;

        span {
          font-size: 28px;
        }
      }
    }
    
  }

  // fonts media query starts here
  @media (max-width: 540px) {
    .innovation .container .features {
      align-items: center;
    }
    h2 {
      font-size: 28px !important;
    }
    .key-offerings .key-offering-heading .highlights span {
      font-size: 16px !important;
    }
    .key-offerings {
      padding-bottom: 0px !important;
    }
    .projects{
      padding: 10px 15px 50px 15px !important;
    }
    .projects h2 {
      margin-bottom: 70px !important;
      margin-top: 15px;
    }
    .story-heading {
      margin: 10px 10px 70px 10px !important;
    }
    .impact-metrics .matrics-container .metric {
      border: 2px solid #86a3e9bd !important;
      border-radius: 10px;
      padding-bottom: 10px;
      &:hover{
        transform: translateY(-5px);
        box-shadow: 10px 19px 25px 0 rgba(0,0,0,.19);
      }
    }

    .impact-metrics .matrics-container .metric h3 {
      padding: 10px 0px 0px 0px !important;
    }
    
  }
 

  .impact-metrics {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 65px;
    width: 100%;
    align-self: center;

    h2 {
      font-size: 32px;
      font-weight: 600;
      margin: 20px 0px 70px 0px;
      color: #0041e8;
    }
    span {
      color: #1a202c;
    }

    .matrics-container {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 2vw; // Gap relative to the viewport width
      flex-wrap: wrap;

      .metric {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%; // Make each metric relative to the container width
        min-width: 150px; // Ensure a minimum size for small screens

        h3 {
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 8vw;
          font-size: 32px;
          font-weight: 600;
          margin: 10px 0px 10px 0px;
          color: #0041e8;
        }

        p {
          font-size: 18px;
          color: #1a202c;
          font-weight: 500;
          text-align: center;
          line-height: 25px;
          margin-bottom: 0rem;
        }
      }
    }

    @media (max-width: 1024px) {
      padding-bottom: 50px;
      width: 90%;

      h2 {
        font-size: 32px;
      }

      .matrics-container {
        gap: 4vw; // Adjust gap for smaller screens

        .metric {
          width: 40%; // Adjust width for smaller screens

          h3 {
            width: 16vw; // Adjust size for smaller screens
            font-size: 32px;
            margin: 10px 0px 0px 0px;
          }

          p {
            font-size: 20px;
            text-align: center;
          }
          
        }
        .haazarpx-seperator {
          border-right: 2px solid rgba(201, 216, 252, 0.7411764706);
        }
      } 
    }
    @media (max-width: 540px) {
      .matrics-container {
        gap: 4vw;  

        .metric {
          width: 40%;  

          h3 {
            font-size: 28px;  
          }

          p {
            font-size: 15px;  
            text-align: center;
            line-height: 20px;
          }
        }
      }

    }
  }

  .key-offerings {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 70px 0px 70px 0px;
    // padding-bottom: 65px;

    .key-offering-heading {
      color: white;
      display: flex;
      align-self: flex-end;
      flex-direction: column;
      align-items: center;
      padding-right: 18%;

      h2 {
        font-size: 32px;
        margin: 30px 0px 70px 0px;;
        font-weight: 600;
        color: #0041e8;
      }
      .h2-highlight {
        color: #1a202c;
      }

      .highlights {
        span {
          margin: 10px;
          cursor: pointer;
          border-bottom: 2px solid #c9d8fcbd;
          padding: 10px;
          border-radius: 8px;
          background-color: white;
          color: #666b78bd;
          font-weight: 500;
          font-size: 20px;
          transition: color 0.3s ease;

          &.active {
            color: #1a202c;
            border-bottom: 4px solid rgba(134, 163, 233, 0.7411764706);
          }
          &:hover {
            background-color: #c9d8fcbd;
          }
        }
      }
    }

    .key-offering-contents {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
      margin-top: 70px;

      .circle-container {
        position: relative;
        border-radius: 50%;
        width: 70%;
        height: 350px;
        opacity: 0;
        transform: translateX(-10%);
        transition: transform .5s ease-out;
        margin-top: -100px;

        .offering-img {
          position: absolute;
          width: 180px;
          height: 180px;
          overflow: hidden;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          border-radius: 50%;
          left: 50%;
          top: 50%;
          transform-origin: center center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:hover {
            transform: scale(1.1);
            box-shadow: 10px 19px 25px 0 rgba(0,0,0,.19);
          }
        }

        .offering-img:nth-child(1) {
          transform: translate(-50%, -50%) translateX(200px);
        }

        .offering-img:nth-child(2) {
          transform: translate(-50%, -50%) rotate(72deg) translateX(200px)
            rotate(-72deg);
        }

        .offering-img:nth-child(3) {
          transform: translate(-50%, -50%) rotate(144deg) translateX(200px)
            rotate(-144deg);
        }

        .offering-img:nth-child(4) {
          transform: translate(-50%, -50%) rotate(216deg) translateX(200px)
            rotate(-216deg);
        }

        .offering-img:nth-child(5) {
          transform: translate(-50%, -50%) rotate(288deg) translateX(200px)
            rotate(-288deg);
        }
      }
      .circle-container.visible {
        opacity: 1;
        transform: translateX(0);
      }
      
      .offering-img-boxed {
        width: 100%;
        max-width: 300px;
        height: auto;
        padding: 5px;
        border: 2px solid #86a3e9bd;
        border-radius: 10px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        margin: 40px auto;
      
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 6px;
        }
      }
      
      @media (max-width: 768px) {
        .circle-container {
          flex-direction: column;
          // margin: 20px 0px 20px 0px !important;
        }
      
        .offering-img {
          display: none;
        }
      }

      .content {
        width: 100%;
        padding: 20px;
        // display: flex;
        // flex-direction: column;
        align-items: center;
        // border: 2px solid #86a3e9bd;
        // border-radius: 10px;
        margin-top: 20px;
        opacity: 0;
        transform: translateX(10%);
        transition: transform .5s ease-out;

        // &:hover {
        //   // transform: scale(1.1);
        //   box-shadow: 10px 19px 25px 0 rgba(0,0,0,.19);
        //   transition: transform 0.3s ease;
        //   transform: translateY(-5px);
        //   // border: 2px solid white;
        //   // transition: transform 0.3s ease, box-shadow 0.3s ease;
        // }

        h1 {
          font-size: 32px;
          font-weight: 600;
          margin-bottom: 50px;
          color: white;
        }

        .highlights {
          // display: flex;
          justify-content: space-around;
          margin-bottom: 20px;
          width: 100%;

          span {
            font-size: 16px;
            font-weight: 400;
            color: #ffe353;
          }
        }

        .details {
          border: 2px solid #86a3e9bd;
          border-radius: 10px;
          padding: 20px;
          &:hover {
            transform: scale(1.1);
            box-shadow: 10px 19px 25px 0 rgba(0,0,0,.19);
            transition: transform 0.3s ease;
            transform: translateY(-5px);
          }
          ul {
            list-style-type: disc;
            padding-left: 20px;

            li {
              // margin: 40px 0px 40px 0px;
              font-size: 18px;
              color: #1a202c;
              text-decoration: none;
              &::marker {
                color: #0041e8;  
                font-size: 20px;  
            }
            }
          }
        }
      }
      .content.visible {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @media (max-width: 1024px) {
    .key-offerings {
      margin-top: 50px;
      margin-bottom: 0px;

      .key-offering-heading {
        padding-right: 0;
        align-self: center;

        h1 {
          font-size: 36px;
          margin-bottom: 20px;
        }

        .highlights {
          span {
            margin: 5px;
          }
        }
      }

      .key-offering-contents {
        flex-direction: row-reverse;
        overflow: hidden;

        .circle-container {
          width: 100%;
          height: 200px;
          margin: 0px 0px 40px 0px;

          .offering-img {
            width: 80px;
            height: 80px;

            &:nth-child(1) {
              transform: translate(-50%, -50%) translateX(100px);
            }

            &:nth-child(2) {
              transform: translate(-50%, -50%) rotate(72deg) translateX(100px)
                rotate(-72deg);
            }

            &:nth-child(3) {
              transform: translate(-50%, -50%) rotate(144deg) translateX(100px)
                rotate(-144deg);
            }

            &:nth-child(4) {
              transform: translate(-50%, -50%) rotate(216deg) translateX(100px)
                rotate(-216deg);
            }

            &:nth-child(5) {
              transform: translate(-50%, -50%) rotate(288deg) translateX(100px)
                rotate(-288deg);
            }
          }
        }

        .content {
          width: 100%;
          padding: 15px 15px 15px 15px;

          h1 {
            font-size: 24px;
            margin-bottom: 20px;
          }

          .highlights {
            span {
              font-size: 14px;
            }
          }

          .details {
            ul {
              padding-left: 10px;

              li {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .story-heading {
    text-align: center;
    color: #0041e8;
    margin: 40px 0px 70px 0px;
    font-size: 32px;
    font-weight: 600;

    .story-heading-span {
      color:  #1a202c;
    }

    @media (max-width: 1024px) {
      font-size: 1.5rem;
      text-align: center;
      margin-top: 35px;
      /* Larger font size for bigger screens */
    }
  }

  .gallery-cta {
    padding: 40px 20px;
    text-align: center;

    h2 {
      margin-bottom: 32px;
    }

    .project,
    .testimonial {
      max-width: 600px;
      margin: 20px auto;
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .testimonials-tabs {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;

      button {
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        border: none;
        background: #007bff;
        color: white;
        border-radius: 5px;
      }
    }

    button {
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      border: none;
      background: #007bff;
      color: white;
      border-radius: 5px;
      margin-top: 20px;
    }
  }

  .collaboration-cta {
        display: flex;
        padding: 4rem;
        justify-content: center;
        align-items: center;
        background-color: #0041e8;

    .cta-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
      padding: 2rem;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      max-width: 900px;
      width: 100%;
    }

    .cta-image {
      width: 100%;
      max-width: 400px;

      /* Adjust max-width for smaller screens */
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }

    .cta-text {
      width: 100%;
      padding: 0;
      text-align: center;

      h2 {
        font-size: 32px;
        color: #1a202c;
        margin-bottom: 1rem;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        color: #1a202c;
        line-height: 1.6rem;
        margin-bottom: 1.5rem;
      }

      button {
        background-color: #0041e8;
        color: #ffffff;
        padding: 8px 20px;
        font-size: 15px;
        border-radius: 5px;
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border: 1px solid #0041e8;

        &:hover {
          transform: scaleY(1.1);
          background-color: #0056b3; // Darker yellow on hover
        }
      }
    }

    @media (min-width: 1024px) {
      // padding: 2rem;
      /* Restore padding for larger screens */

      .cta-content {
        flex-direction: row;
        padding: 2rem;
      }

      .cta-image {
        max-width: 50%;

        /* Adjust image width for larger screens */
        img {
          max-width: 100%;
        }
      }

      .cta-text {
        width: 50%;
        /* Adjust text width for larger screens */
        padding: 0 2rem;
        text-align: left;
        /* Align text to left for larger screens */
      }
    }
  }
}
.counter-sepearator {
  border-right: 2px solid #c9d8fcbd;
}
@media (max-width: 940px) {
  .counter-sepearator {
    border-right: 0px;
  } 
}

@media (max-width: 768px) {
  .mobile-none {
    display: none;
  } 
  .content {
    margin-top: 1px !important;
  }
  .key-offering-contents {
    margin-top: 1px !important;
  }
}

// Animations for the features section
@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.know-more-button {
    background-color: #0041e8;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 15px;
    border-radius: 5px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    border: 1px solid #0041e8;
    margin-top: 20px;
    &:hover {
        transform: scaleX(1.1);
        background-color: #0056b3;  
    }
}

@media (max-width: 768px) {
  .home .collaboration-cta {
    padding: 2rem !important;

    .cta-image img {
      max-width: 100%;
      height: 230px;
      margin-bottom: 20px;
    } 
  }
}

.bentogallery-title {

  font-size: 32px;
  color: #0041e8;
  margin: 20px 5px 70px 5px;
  font-weight: 600;

  @media (max-width: 1024px) {
    .title {
      font-size: 28px !important;
  
    }
  }
}