.hero-section-container {
  margin-bottom: 0;
  background-color: white;

  .hero {
    padding-bottom: 0;
    text-align: center;
    margin-top: 50px;

    h1,
    h2 {
      opacity: 0;
      transform: translateY(20px);
      animation: fadeInUp 1s ease forwards;
    }

    h1 {
      font-size: 60px;
      font-weight: 700;
      font-weight: 700;
      color: #1a202c;
      margin: 70px 12px 20px 12px;  
      // font-family: "Arial Black", sans-serif;
      margin: 70px 12px 20px 12px;  
      // font-family: "Arial Black", sans-serif;
      animation-delay: 0.2s;
    }

    h2 {
      font-size: 28px; /* Relative font size for responsiveness */
      font-weight: normal;
      color: #555;
      margin-bottom: 50px;
      animation-delay: 0.4s;
    }

    .hero-tag {      
      font-size: 15px;
      color: #555;
      // padding: 10px;
      // border-radius: 10px;
      // border: 2px solid #0041e8;

      .hero-tag-bold {
        font-weight: 700;
        color: #0041e8;
        font-size: 18px;
       
      }
    }

    .cta-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transform: translateY(20px);
      animation: fadeInUp 1s ease forwards;
      animation-delay: 0.6s;
      margin-top: 50px;

      button {
        padding: 12px 24px;  
        margin: 0 1vw;  
        border: none;
        border-radius: 8px; 
        font-size: 18px;  
        border: 2px solid #0041e8;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        position: relative;
        overflow: hidden;
        z-index: 1;
        background-color: transparent;
        color: #0041e8;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.2);
          transition: transform 0.3s ease;
          z-index: -1;
          transform: scaleX(0);
          transform-origin: left;
        }

        &:hover::before {
          transform: scaleX(1);
        }

        &.watch-video,
        &.teach-with-ai {
          border: 2px solid #86a3e9bd;
        }

        &:hover {
          transform: scale(1.1) rotate(5deg);
          background-color: #0041e8;
          // border: 1px solid #0041e8;
          color: white;
        }
      }
    }
  }
  @media (max-width: 650px) {
    .hero h1 {
        font-size: 35px !important;
    }
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Media queries for responsiveness */
  @media screen and (max-width: 1024px) {
    .hero-section-container .hero h1 {
      font-size: 5vw; /* Adjusted font size for smaller screens */
    }

    .hero-section-container .hero h2 {
      font-size: 3vw; /* Adjusted font size for smaller screens */
      margin-bottom: 4vw; /* Adjusted margin for smaller screens */
    }

    .hero-section-container .hero .cta-buttons button {
      padding: 2vw 4vw; /* Adjusted padding for smaller screens */
      margin: 0 2vw; /* Adjusted margin for smaller screens */
      border-radius: 3vw; /* Adjusted border radius for smaller screens */
      font-size: 2vw; /* Adjusted font size for smaller screens */
    }
  }

  @media screen and (max-width: 1024px) {
    .hero h1 {
      font-size: 48px;  
    }

    .hero h2 {
      font-size: 20px; /* Further adjusted font size for smaller screens */
      margin-bottom: 70px; /* Further adjusted margin for smaller screens */
    }

     .hero .cta-buttons button {
      padding: 8px 20px; /* Further adjusted padding for smaller screens */
      margin: 0 2.5vw; /* Further adjusted margin for smaller screens */
      border-radius: 10px; /* Further adjusted border radius for smaller screens */
      font-size: 15px; /* Further adjusted font size for smaller screens */
    }
  }

  .statistics {
    padding: 0px 0px 40px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* box-sizing: content-box !important; */
    
  
    .outer-left,
    .outer-right {
      width: 25%;
      padding: 0 2%; /* Use percentage for padding */
      position: relative;
  
      .top-outer-left-box,
      .top-outer-right-box {
        position: relative;
  
        img:first-child {
          width: 100%;
          transition: transform 0.3s ease;
        }
  
        &:hover img:first-child {
          transform: scale(1.05);
        }
  
        img.line7,
        img.rect382 {
          position: absolute;
        }

        img.line7 {
          bottom: 1vw; /* Use vw for positioning */
          left: 0;
          width: 100%;
        }
  
        img.rect382 {
          bottom: 1vw; /* Use vw for positioning */
          left: 0;
          z-index: 100;
          width: 100%;
        }
  
        margin-bottom: 1vw; /* Use vw for margin */
  
        div {
          position: absolute;
          z-index: 101;
          bottom: 3vw;  
          text-align: left;
          padding-left: 2vw;  
  
          h2 {
            font-size: 2vw; 
            font-weight: 600;
            color: white;
            transition: transform 0.3s ease;
          }
  
          p {
            font-size: 20px;
            color: white;
            transition: transform 0.3s ease;

      
          }



  
          &:hover h2,
          &:hover span {
            transform: scale(1.1);
          }
        }
      }
  
      .bottom-outer-left-box,
      .bottom-outer-right-box {
        height: 7vw;  
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        transition: background-color 0.3s ease, transform 0.3s ease;
  
        a {
          font-size: 20px; /* Use vw for font size */
          color: white;
        }
  
        &:hover {
          background-color: #333333;
          transform: scale(1.05);
        }
      }
    }
  
    .middle {
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
  
      .inner-left,
      .inner-middle,
      .inner-right {
        width: 30%;
        position: relative;
  
        img {
          width: 100%;
          transition: transform 0.3s ease;
        }
  
        &:hover {
          transform: scale(1.05);
        }
  
        div {
          position: absolute;
          z-index: 101;
          bottom: 0;
          text-align: center;
          color: white;
          padding: 0.5vw; /* Use vw for padding */
          transition: background-color 0.3s ease;
          display: flex;
          flex-direction: column;
          align-items: center;
  
          p {
            display: block;
            font-size: 20px; /* Use vw for font size */
            margin-bottom: 1vw; /* Use vw for margin */
          }
  
          button {
            background-color: #0041e8;
            border: 2px solid #0056b3;
            color: white;
            border-radius: 10px;  
            padding: 8px 25px;  
            font-size: 1vw;  
            font-weight: 600;
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.3s ease;
            margin-bottom: 5px;
  
            &:hover {
              background-color: #0056b3;
              transform: scale(1.1);
              color: white;
            }
          }
        }
      }
  
      .inner-left {
        div {
          width: 100%;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          background: linear-gradient(to right, #666666, black);
        }
      }
  
      .inner-middle {
        position: relative;
        width: 25%;
  
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 2vw; /* Use vw for border radius */
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
          transition: opacity 0.3s ease;
          opacity: 1; /* Initially hidden */
          pointer-events: none; /* Allows clicks to go through */
        }
      }
    }
  
    .outer-right .bottom-outer-right-box {
      background-color: black;
    }
  }
  

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes scaleUp {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes rotateIn {
    to {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
}
@media (max-width: 768px) {
  .mobile-none {
      display: none !important;
  } 
  .hero-section-container .hero h2 {
      font-size: 18px !important;
      margin: 25px;
  }
}
@media (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}
.stat-mobile {
  width: 100% !important;
  align-items: center !important;

  .top-outer-left-card, .top-outer-right-card {
     margin-top: 25px;
  }
  .top-outer-left-card-down, .top-outer-right-card-down {
    margin-top: 0px;
 }

  .top-outer-left-card img {
      width: 100%;
      margin-bottom: 21px;
      
  }

  .top-outer-right-card img {
      width: 100%;
      margin-bottom: 21px;
  }

  // .left-abstarct {
  //    margin-top: -200px;
  // }

  // .right-abstarct {
  //     margin-top: -200px;
  // }

  .top-outer-left-card-down img {
      width: 100%;
      // height: 200px;
      border-radius: 13px;
      border: 1px solid #0041e8;
      position: relative; 
  }

  .top-outer-right-card-down img {
      width: 100%;
      // height: 200px;
      border-radius: 13px;
      border: 1px solid #0041e8;
      position: relative; 
  }
  .over-h2 {
    // margin-top: -200px !important;
    color: white !important;
    font-size: 20px !important;
  }
  .over-p {
    position: absolute;
    top: 15%; 
    color: white !important;
    font-size: 15px !important;
    margin: 0px 2px 0px 2px;
  }
  .over-p-down {
    position: absolute;
    top: 40%;
    left: 10% !important; 
    color: white !important;
    font-size: 14px !important;
    text-align: center !important;
  }

  .bottom-down {
     background:black;
     margin: 25px 18px 50px 18px;
     border-radius: 13px;


    img {
      width: 100%;
      border-radius: 20px;
      border: 1px solid #0041e8;
      position: relative;
    }
    p {
      color: white;
      font-size: 14px;
      padding: 0px 10px 10px 10px;
      position: absolute;
      top: 30%; 
      text-align: center;
      // margin-top: -90px;
    }

  }
  .photo-grid {
    margin: 5px !important;
  }
 
  .teach-with-ai-mob{
    background-color: #0041e8;
    border: 2px solid #0056b3;
    color: white;
    border-radius: 10px;  
    padding: 8px 25px;  
    font-size: 15px;  
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    // margin-bottom: 5px;

    &:hover {
      background-color: #0056b3;
      transform: scale(1.1);
      color: white;
    }
  }
}

@media (max-width: 1024px) {
  .hero-section-container .statistics .outer-left .top-outer-left-box div p, .hero-section-container .statistics .outer-left .top-outer-right-box div p, .hero-section-container .statistics .outer-right .top-outer-left-box div p, .hero-section-container .statistics .outer-right .top-outer-right-box div p {
    font-size: 16px;
  }
  .hero-section-container .statistics .outer-left .bottom-outer-left-box a, .hero-section-container .statistics .outer-left .bottom-outer-right-box a, .hero-section-container .statistics .outer-right .bottom-outer-left-box a, .hero-section-container .statistics .outer-right .bottom-outer-right-box a {
    font-size: 16px;
  }
  .hero-section-container .statistics .middle .inner-left div p, .hero-section-container .statistics .middle .inner-middle div p, .hero-section-container .statistics .middle .inner-right div p {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .hero-section-container .statistics .outer-left .top-outer-left-box div p, .hero-section-container .statistics .outer-left .top-outer-right-box div p, .hero-section-container .statistics .outer-right .top-outer-left-box div p, .hero-section-container .statistics .outer-right .top-outer-right-box div p {
    font-size: 16px;
  }
  .hero-section-container .statistics .outer-left .bottom-outer-left-box a, .hero-section-container .statistics .outer-left .bottom-outer-right-box a, .hero-section-container .statistics .outer-right .bottom-outer-left-box a, .hero-section-container .statistics .outer-right .bottom-outer-right-box a {
    font-size: 16px;
  }
  .hero-section-container .statistics .middle .inner-left div p, .hero-section-container .statistics .middle .inner-middle div p, .hero-section-container .statistics .middle .inner-right div p {
    font-size: 16px;
  }
}

.feature-button-home {
  display: none;
  border: none !important;
  background-color: white;
  color: #0041e8 !important;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 22px;
  position: relative;  
  overflow: hidden;    
  margin: 0 auto;      
}

.feature-button-home::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;  
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: horizontalShine 2s linear infinite;
}

@keyframes horizontalShine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@media (max-width: 1024px) {
  .feature-button-home {
    display: block;
  }
}
