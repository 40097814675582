// fellowhip css
@import '../index.css';

.fellowship-page {
  min-height: 100vh;
  overflow-x: hidden;
  
  // Global section styles
  section {
    padding: 100px 0;
    position: relative;
    
    &:before, &:after {
      content: '';
      position: absolute;
      background: linear-gradient(135deg, rgba(0, 65, 232, 0.05) 0%, rgba(134, 163, 233, 0.05) 100%);
      border-radius: 50%;
      z-index: -1;
    }
    
    &:before {
      top: -100px;
      right: -100px;
      width: 300px;
      height: 300px;
    }
    
    &:after {
      bottom: -100px;
      left: -100px;
      width: 350px;
      height: 350px;
    }
    
    @media (max-width: 768px) {
      padding: 70px 0;
    }
    
    @media (max-width: 480px) {
      padding: 50px 0;
    }
    
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }
    
    .section-title {
      font-size: 3rem;
      font-weight: 800;
      text-align: center;
      margin-bottom: 70px;
      position: relative;
      color: #0041e8;
      letter-spacing: -0.5px;
      
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 6px;
        background: linear-gradient(90deg, #0041e8, #86a3e9);
        border-radius: 10px;
      }
      
      @media (max-width: 768px) {
        font-size: 2.5rem;
        margin-bottom: 50px;
      }
    }
  }
  
  // Decorative shapes
  .decorative-shape {
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(0, 65, 232, 0.03) 0%, rgba(134, 163, 233, 0.03) 100%);
    z-index: 0;
    
    &.shape-1 {
      width: 300px;
      height: 300px;
      top: 10%;
      right: -150px;
      animation: floatAnimation 12s ease-in-out infinite;
    }
    
    &.shape-2 {
      width: 200px;
      height: 200px;
      bottom: 20%;
      left: -100px;
      animation: floatAnimation 10s ease-in-out infinite reverse;
    }
    
    &.shape-3 {
      width: 250px;
      height: 250px;
      bottom: -125px;
      right: 10%;
      animation: floatAnimation 15s ease-in-out infinite;
    }
    
    &.shape-4 {
      width: 350px;
      height: 350px;
      top: -175px;
      left: 5%;
      animation: floatAnimation 18s ease-in-out infinite reverse;
    }
    
    &.shape-5 {
      width: 150px;
      height: 150px;
      bottom: 10%;
      right: -75px;
      animation: floatAnimation 8s ease-in-out infinite;
    }
  }
  
  // Hero section with video
  .fellowship-hero {
    padding-top: 140px;
    padding-bottom: 80px;
    background: linear-gradient(135deg, #f9f9ff 0%, #f0f5ff 100%);
    position: relative;
    z-index: 1;
    overflow: hidden;
    
    &:before {
      content: '';
      position: absolute;
      top: -50px;
      right: -50px;
      width: 300px;
      height: 300px;
      background: radial-gradient(circle, rgba(0, 65, 232, 0.1) 0%, rgba(0, 65, 232, 0) 70%);
      z-index: 0;
    }
    
    &:after {
      content: '';
      position: absolute;
      bottom: -50px;
      left: -50px;
      width: 300px;
      height: 300px;
      background: radial-gradient(circle, rgba(0, 65, 232, 0.1) 0%, rgba(0, 65, 232, 0) 70%);
      z-index: 0;
    }
    
    .container {
      position: relative;
      z-index: 2;
    }
    
    .hero-content {
      text-align: center;
      margin-bottom: 40px;
      
      h1 {
        font-size: 4rem;
        font-weight: 800;
        margin-bottom: 20px;
        color: #0041e8;
        letter-spacing: -1px;
        position: relative;
        display: inline-block;
        
        &:after {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
          width: 80px;
          height: 5px;
          background: linear-gradient(90deg, #0041e8, #86a3e9);
          border-radius: 10px;
        }
        
        @media (max-width: 768px) {
          font-size: 3rem;
        }
        
        @media (max-width: 480px) {
          font-size: 2.5rem;
        }
      }
      
      .hero-subtitle {
        font-size: 1.4rem;
        color: #555;
        max-width: 700px;
        margin: 30px auto 0;
        line-height: 1.5;
        
        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
      }
    }
    
    .video-container {
      position: relative;
      z-index: 1;
      width: 80%;
      max-width: 900px;
      margin: 0 auto 50px;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
      transform: perspective(1000px) rotateX(2deg);
      transition: transform 0.5s ease;
      
      &:hover {
        transform: perspective(1000px) rotateX(0deg);
      }
      
      &:before {
        content: '';
        display: block;
        padding-top: 56.25%; // 16:9 aspect ratio
        background: rgba(0, 0, 0, 0.05);
      }
      
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 3px solid rgba(0, 65, 232, 0.1);
        border-radius: 24px;
        pointer-events: none;
      }
      
      .play-button-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        pointer-events: none;
        
        .play-button {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: linear-gradient(135deg, #0041e8, #2563eb);
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          box-shadow: 0 10px 30px rgba(0, 65, 232, 0.4);
          cursor: pointer;
          transition: all 0.3s ease;
          pointer-events: auto;
          
          svg {
            font-size: 2rem;
            margin-left: 5px;
          }
          
          &:hover {
            transform: scale(1.1);
            box-shadow: 0 15px 40px rgba(0, 65, 232, 0.5);
          }
        }
      }
      
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
      
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    
    .moving-line-container {
      position: relative;
      z-index: 1;
      width: 100%;
      overflow: hidden;
      background: linear-gradient(45deg, #0041e8, #5485ff, #86a3e9, #ffffff);
      background-size: 300% 100%;
      animation: gradientShift 15s ease infinite;
      margin: 60px 0;
      box-shadow: 0 10px 35px rgba(0, 65, 232, 0.25);
      border-radius: 12px;
      backdrop-filter: blur(10px);
      border: 1px solid rgba(255, 255, 255, 0.4);
      
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,.1)' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
        opacity: 0.5;
        z-index: 0;
      }
      
      .moving-line {
        display: flex;
        align-items: center;
        height: 70px;
        position: relative;
        z-index: 2;
        
        .moving-text {
          display: flex;
          white-space: nowrap;
          animation: moveText 20s linear infinite;
          
          span {
            display: inline-block;
            font-size: 1.4rem;
            font-weight: 700;
            margin-right: 50px;
            position: relative;
            padding: 0 20px;
            letter-spacing: 0.5px;
            background: linear-gradient(to right, #ffffff, #e0e8ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
            
            &:after {
              content: '•';
              position: absolute;
              right: -25px;
              top: 50%;
              transform: translateY(-50%);
              color: #ffffff;
              text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
              -webkit-text-fill-color: white;
            }
          }
        }
      }
    }
    
    .hero-cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      
      .apply-button {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 18px 36px;
        background: linear-gradient(135deg, #0041e8 0%, #2563eb 100%);
        color: white;
        border: none;
        border-radius: 50px;
        font-size: 1.2rem;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.4s ease;
        box-shadow: 0 10px 25px rgba(0, 65, 232, 0.4);
        position: relative;
        overflow: hidden;
        
        &.pulse {
          animation: pulse 2s infinite;
        }
        
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
          transition: transform 0.5s ease;
          transform: translateX(-100%);
        }
        
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 15px 30px rgba(0, 65, 232, 0.5);
          animation: none;
          
          &:before {
            transform: translateX(0);
          }
        }
        
        span {
          position: relative;
          z-index: 1;
        }
        
        svg {
          position: relative;
          z-index: 1;
          transition: transform 0.3s ease;
        }
        
        &:hover svg {
          transform: rotate(45deg);
        }
      }
      
      .deadline-notice {
        margin-top: 20px;
        font-size: 1.1rem;
        font-weight: 600;
        color: #0041e8;
        background: rgba(0, 65, 232, 0.05);
        padding: 10px 20px;
        border-radius: 50px;
      }
    }
  }
  
  // Key Benefits section
  .key-benefits {
    background-color: white;
    position: relative;
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: 
        radial-gradient(circle at 10% 10%, rgba(0, 65, 232, 0.03) 0%, transparent 20%),
        radial-gradient(circle at 90% 90%, rgba(0, 65, 232, 0.03) 0%, transparent 20%);
      z-index: 0;
    }
    
    .container {
      position: relative;
      z-index: 1;
    }
    
    .benefits-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
      
      @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      }
      
      @media (max-width: 576px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }
      
      .benefit-card {
        background: white;
        border-radius: 20px;
        padding: 40px 30px;
        text-align: center;
        transition: all 0.4s ease;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        border: 1px solid #f0f0f0;
        position: relative;
        z-index: 1;
        overflow: hidden;
        
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background: linear-gradient(90deg, #0041e8, #86a3e9);
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 0.4s ease;
          z-index: 1;
        }
        
        &:hover {
          transform: translateY(-15px);
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
          border-color: #e0e0e0;
          
          &:before {
            transform: scaleX(1);
            transform-origin: left;
          }
          
          .benefit-icon {
            transform: scale(1.1) translateY(-5px);
            background: linear-gradient(135deg, #0041e8 0%, #2563eb 100%);
            
            svg {
              color: white;
            }
          }
        }
        
        .benefit-icon {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          background: rgba(0, 65, 232, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 25px;
          transition: all 0.4s ease;
          
          svg {
            font-size: 3rem;
            color: #0041e8;
            transition: all 0.3s ease;
          }
        }
        
        h3 {
          font-size: 1.7rem;
          font-weight: 700;
          margin-bottom: 15px;
          color: #222;
          position: relative;
          display: inline-block;
          
          &:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 3px;
            background: #e0e0e0;
            border-radius: 2px;
            transition: all 0.3s ease;
          }
        }
        
        &:hover h3:after {
          width: 60px;
          background: #0041e8;
        }
        
        p {
          color: #555;
          line-height: 1.7;
          font-size: 1.05rem;
          margin-top: 20px;
        }
      }
    }
  }
  
  // How to Apply section
  .how-to-apply {
    background: #f8f9ff;
    position: relative;
    overflow: hidden;
    
    // Link button styling
    .link-button {
      background: none;
      border: none;
      padding: 0;
      color: #0041e8;
      text-decoration: underline;
      font-size: inherit;
      font-family: inherit;
      cursor: pointer;
      display: inline;
      
      &:hover {
        color: #0035c9;
        text-decoration: none;
      }
      
      &:focus {
        outline: none;
        text-decoration: none;
      }
    }
    
    &:before, &:after {
      content: '';
      position: absolute;
      background: rgba(0, 65, 232, 0.03);
      border-radius: 50%;
      z-index: 0;
    }
    
    &:before {
      width: 400px;
      height: 400px;
      top: -200px;
      right: -200px;
    }
    
    &:after {
      width: 300px;
      height: 300px;
      bottom: -150px;
      left: -150px;
    }
    
    .container {
      position: relative;
      z-index: 1;
    }
    
    .steps-container {
      max-width: 850px;
      margin: 0 auto;
      position: relative;
      
      &:before {
        content: '';
        position: absolute;
        top: 30px;
        bottom: 30px;
        left: 30px;
        width: 3px;
        background: linear-gradient(to bottom, #0041e8 0%, rgba(0, 65, 232, 0.3) 100%);
        border-radius: 3px;
        z-index: 0;
      }
      
      .step {
        display: flex;
        align-items: flex-start;
        margin-bottom: 50px;
        position: relative;
        
        &:last-child {
          margin-bottom: 0;
        }
        
        .step-number {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: linear-gradient(135deg, #0041e8 0%, #2563eb 100%);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          font-weight: 700;
          margin-right: 30px;
          flex-shrink: 0;
          position: relative;
          z-index: 1;
          box-shadow: 0 8px 20px rgba(0, 65, 232, 0.3);
          transition: all 0.3s ease;
        }
        
        &:hover .step-number {
          transform: scale(1.1);
          box-shadow: 0 10px 25px rgba(0, 65, 232, 0.4);
        }
        
        .step-content {
          flex-grow: 1;
          background: white;
          padding: 30px;
          border-radius: 20px;
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
          transition: all 0.3s ease;
          border: 1px solid rgba(0, 0, 0, 0.03);
          position: relative;
          
          &:before {
            content: '';
            position: absolute;
            top: 25px;
            left: -12px;
            width: 24px;
            height: 24px;
            background: white;
            transform: rotate(45deg);
            border-left: 1px solid rgba(0, 0, 0, 0.03);
            border-bottom: 1px solid rgba(0, 0, 0, 0.03);
          }
          
          h3 {
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 15px;
            color: #0041e8;
            transition: all 0.3s ease;
          }
          
          p {
            color: #555;
            margin: 0;
            font-size: 1.05rem;
            line-height: 1.6;
            
            a {
              color: #0041e8;
              text-decoration: none;
              font-weight: 600;
              position: relative;
              
              &:after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background: #0041e8;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 0.3s ease;
              }
              
              &:hover:after {
                transform: scaleX(1);
                transform-origin: left;
              }
            }
          }
        }
        
        &:hover .step-content {
          transform: translateX(10px);
          box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
        }
      }
    }
    
    .apply-cta {
      text-align: center;
      margin-top: 70px;
      
      .apply-button {
        display: inline-flex;
        align-items: center;
        gap: 12px;
        padding: 20px 45px;
        background: linear-gradient(135deg, #0041e8 0%, #2563eb 100%);
        color: white;
        border: none;
        border-radius: 50px;
        font-size: 1.3rem;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.4s ease;
        box-shadow: 0 10px 30px rgba(0, 65, 232, 0.4);
        position: relative;
        overflow: hidden;
        
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
          transition: transform 0.5s ease;
          transform: translateX(-100%);
        }
        
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 15px 35px rgba(0, 65, 232, 0.5);
          
          &:before {
            transform: translateX(0);
          }
          
          svg {
            transform: rotate(45deg);
          }
        }
        
        span {
          position: relative;
          z-index: 1;
          letter-spacing: 0.5px;
        }
        
        svg {
          position: relative;
          z-index: 1;
          transition: transform 0.3s ease;
        }
      }
    }
  }
  
  // Testimonials section
  .fellowship-testimonials {
    background: white;
    position: relative;
    overflow: hidden;
    
    // Success stats
    .fellowship-success-stats {
      margin-bottom: 80px;
      
      .stats-container {
        display: flex;
        justify-content: center;
        gap: 80px;
        flex-wrap: wrap;
        
        @media (max-width: 768px) {
          gap: 40px;
        }
        
        .stat-item {
          text-align: center;
          padding: 20px;
          
          h3 {
            font-size: 4rem;
            font-weight: 800;
            margin-bottom: 15px;
            color: #0041e8;
            background: linear-gradient(90deg, #0041e8, #2563eb);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
            display: inline-block;
            
            &:after {
              content: '';
              position: absolute;
              bottom: 5px;
              right: -20px;
              width: 15px;
              height: 15px;
              background: #0041e8;
              border-radius: 50%;
            }
          }
          
          p {
            font-size: 1.2rem;
            font-weight: 600;
            color: #444;
            text-transform: uppercase;
            letter-spacing: 1px;
          }
        }
      }
    }
    
    // Content wrapper for form and testimonials
    .fellowship-content-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
      margin-bottom: 60px;
      
      @media (max-width: 992px) {
        grid-template-columns: 1fr;
        gap: 60px;
      }
      
      // Fellowship application form
      .fellowship-form {
        background: white;
        border-radius: 20px;
        padding: 40px;
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.03);
        
        h3 {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 15px;
          color: #0041e8;
          position: relative;
          display: inline-block;
          
          &:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 60px;
            height: 3px;
            background: linear-gradient(90deg, #0041e8, #86a3e9);
            border-radius: 2px;
          }
        }
        
        p {
          font-size: 1.1rem;
          color: #555;
          margin-bottom: 25px;
          line-height: 1.6;
        }
        
        .success-message {
          background: rgba(0, 180, 100, 0.1);
          color: #00b464;
          padding: 15px;
          border-radius: 10px;
          margin-bottom: 20px;
          font-weight: 600;
          border-left: 4px solid #00b464;
        }
        
        form {
          display: flex;
          flex-direction: column;
          gap: 15px;
          
          .form-group {
            position: relative;
            
            input, textarea {
              width: 100%;
              padding: 15px;
              border: 1px solid #e0e0e0;
              border-radius: 10px;
              font-size: 1rem;
              transition: all 0.3s ease;
              background: #f9f9ff;
              
              &:focus {
                outline: none;
                border-color: #0041e8;
                background: white;
                box-shadow: 0 5px 15px rgba(0, 65, 232, 0.1);
              }
              
              &::placeholder {
                color: #888;
              }
              
              &:disabled {
                background: #f5f5f5;
                cursor: not-allowed;
                opacity: 0.7;
              }
            }
            
            textarea {
              min-height: 120px;
              resize: vertical;
            }
            
            .error-message {
              color: #e53e3e;
              font-size: 0.85rem;
              margin-top: 5px;
              margin-left: 5px;
            }
          }
          
          .submit-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 15px 30px;
            background: linear-gradient(135deg, #0041e8 0%, #2563eb 100%);
            color: white;
            border: none;
            border-radius: 10px;
            font-size: 1.1rem;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.3s ease;
            box-shadow: 0 8px 20px rgba(0, 65, 232, 0.3);
            margin-top: 15px;
            position: relative;
            overflow: hidden;
            
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
              transition: transform 0.5s ease;
              transform: translateX(-100%);
            }
            
            &:hover {
              transform: translateY(-5px);
              box-shadow: 0 15px 30px rgba(0, 65, 232, 0.4);
              
              &:before {
                transform: translateX(0);
              }
              
              svg {
                transform: translateX(5px);
              }
            }
            
            &:disabled {
              background: #93a8e9;
              cursor: not-allowed;
              transform: none;
            }
            
            svg {
              transition: transform 0.3s ease;
            }
          }
        }
      }
    }
    
    // Testimonial slider
    .testimonial-slider {
      position: relative;
      
      .testimonial-container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        
        .slider-arrow {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: white;
          border: 1px solid rgba(0, 65, 232, 0.15);
          color: #0041e8;
          font-size: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease;
          z-index: 10;
          position: absolute;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
          
          &:hover {
            background: #0041e8;
            color: white;
            box-shadow: 0 10px 30px rgba(0, 65, 232, 0.2);
          }
          
          &.prev-arrow {
            left: 0;
            
            @media (max-width: 768px) {
              left: 20px;
            }
          }
          
          &.next-arrow {
            right: 0;
            
            @media (max-width: 768px) {
              right: 20px;
            }
          }
          
          @media (max-width: 576px) {
            width: 50px;
            height: 50px;
            font-size: 1rem;
          }
        }
        
        .testimonial-card {
          max-width: 800px;
          margin: 0 auto;
          background: white;
          border-radius: 20px;
          padding: 50px 80px;
          box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
          position: relative;
          border: 1px solid rgba(0, 0, 0, 0.03);
          transition: all 0.5s ease;
          
          @media (max-width: 768px) {
            padding: 50px 60px;
          }
          
          @media (max-width: 576px) {
            padding: 40px 30px;
          }
          
          .quote-icon {
            position: absolute;
            top: 30px;
            left: 30px;
            font-size: 3rem;
            color: rgba(0, 65, 232, 0.1);
            line-height: 1;
          }
          
          .testimonial-text {
            font-size: 1.3rem;
            line-height: 1.8;
            color: #444;
            font-style: italic;
            margin-bottom: 40px;
            position: relative;
            
            @media (max-width: 576px) {
              font-size: 1.1rem;
            }
          }
          
          .testimonial-author {
            border-top: 1px solid #eee;
            padding-top: 30px;
            
            h4 {
              font-size: 1.2rem;
              font-weight: 700;
              color: #0041e8;
              margin-bottom: 5px;
            }
            
            p {
              color: #666;
              font-size: 1rem;
            }
          }
          
          .slider-dots {
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            gap: 10px;
            
            .slider-dot {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: rgba(0, 65, 232, 0.2);
              border: none;
              transition: all 0.3s ease;
              cursor: pointer;
              padding: 0;
              
              &.active {
                background: #0041e8;
                transform: scale(1.2);
              }
              
              &:hover {
                background: rgba(0, 65, 232, 0.5);
              }
            }
          }
        }
      }
    }
    
    // Add a decorative element
    &:before {
      content: '';
      position: absolute;
      width: 500px;
      height: 500px;
      border-radius: 50%;
      background: linear-gradient(135deg, rgba(0, 65, 232, 0.02) 0%, rgba(134, 163, 233, 0.02) 100%);
      top: -250px;
      right: -250px;
      z-index: 0;
    }
    
    &:after {
      content: '';
      position: absolute;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      background: linear-gradient(135deg, rgba(0, 65, 232, 0.02) 0%, rgba(134, 163, 233, 0.02) 100%);
      bottom: -200px;
      left: -200px;
      z-index: 0;
    }
  }
}

// Slide animation for testimonials
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

// Pulse animation for the apply button
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 65, 232, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 65, 232, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 65, 232, 0);
  }
}

// Floating animation for some elements
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

// Keyframes for the moving text animation
@keyframes moveText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

// Keyframes for the gradient shift animation
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} 