.about-history {
  position: relative;
  align-self: center;
  width: 80%;
  background-size: cover;
  background-position: center;
  color: #0041e8;
  border-radius: 8px;
  // margin: 50px 0px;
  transition: background-image 0.5s ease-in-out;

  h1 {
    font-size: 32px;
    color: #0041e8;
    margin: 30px 0px 70px 0px;
    font-weight: 600; 
    text-align: center;
  }
  @media(max-width: 1024px) {
    h1 {
      font-size: 28px;
      margin: 50px 0px 50px 0px;
    }
  }

  .story-content {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 400px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    transition: background-image 0.5s ease-in-out;

    .student-name {
      width: 20%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .child-image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0px 0px 20px 20px;
        border: 2px solid rgba(201, 216, 252, 0.7411764706);
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-left: 20px;
        color: white;
        align-self: center;
        text-align: center;
      }
    }

    .story {
      width: 80%;
      padding: 20px;
      position: relative;
      z-index: 2;

      li {
        font-size: 18px;
        color:  white;
        line-height: 35px;
        text-align: left;
        font-weight: 500;
      }
    }
  }

  // Responsive styles for small devices
  @media (max-width: 1024px) {
    width: 90%;
    height: auto;

    .story-content {
      width: 100% !important;
      height: 250px;
      height: 250px;
      flex-direction: column !important;
      align-items: center !important;

      .student-name {
        width: 90%;
        padding: 10px;
        text-align: center;
      }

      .story {
        width: 90%;
        padding: 30px 0px 0px 0px;
      }
    }
  }

  // fonts media query starts here
  @media (max-width: 540px) {
    .story-content .story p {
       text-align: center;
    }
  }

  .navigation-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .pagination-nav {
      display: flex;
      justify-content: center;
      margin-right: 5px;
      cursor: pointer;

      a {
        display: inline-block;
        margin: 5px;
        cursor: pointer;
        border-bottom: 2px solid #c9d8fcbd;
        padding: 5px;
        border-radius: 8px;
        background-color: white;
        color: #666b78bd;
        font-weight: 500;
        font-size: 15px;
        transition: color 0.3s ease;
        text-decoration: none;

        &.active,
        &:hover {
          // background: #0041e8;
          color: #1a202c;
          border-bottom: 4px solid rgba(134, 163, 233, 0.7411764706);
        }
      }
    }
    .next-story-button {
      width: 35%;
      margin: 0px 10px 0px 0px;
      align-self: flex-end;
      padding: 8px 8px;
      border-radius: 8px;
      font-size: 30px;
      font-weight: 400;
      color: #0041e8;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
        // border: 2px solid white;
      }

      &:focus {
        outline: none;
      }
    }
  }
  @media(max-width: 768px) {
    .navigation-container {
      .pagination-nav {
        a {
          font-size: 12px;
          padding: 3px;
        }
      }
      .next-story-button {
        font-size: 22px;
        display: none;
        margin: 0px 5px 0px 0px;
        width: 40%;
      }
    }
  }

  .navigation-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    .pagination-nav {
      display: flex;
      justify-content: center;
      margin-right: 5px;
      cursor: pointer;

      a {
        display: inline-block;
        margin: 5px;
        cursor: pointer;
        border-bottom: 2px solid #c9d8fcbd;
        padding: 5px;
        border-radius: 8px;
        background-color: white;
        color: #666b78bd;
        font-weight: 500;
        font-size: 16px;
        transition: color 0.3s ease;
        text-decoration: none;

        &.active {
          color: #1a202c;
          border-bottom: 4px solid rgba(134, 163, 233, 0.7411764706);
        }
        &:hover {
          background-color:rgba(201, 216, 252, 0.7411764706);
        }
      }
    }
    .next-story-button {
      width: 35%;
      margin: 0px 10px 0px 0px;
      align-self: flex-end;
      padding: 8px 8px;
      border-radius: 8px;
      font-size: 30px;
      font-weight: 400;
      color: #0041e8;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
        // border: 2px solid white;
      }

      &:focus {
        outline: none;
      }
    }
  }
  @media(max-width: 768px) {
    .navigation-container {
      justify-content: flex-start;
      margin-top: 10px;
      .pagination-nav {
        a {
          font-size: 13px;
          padding: 3px;
        }
      }
      .next-story-button {
        display: none;
        margin: 0px 5px 0px 0px;
        width: 40%;
      }
    }
  }
  .journey-overlay {
    position: absolute;
    /* top: 20px; */
    /* left: 20px; */
    /* right: 20px; */
    /* bottom: 20px; */
    align-self: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: 0px 10px 10px 0px;
    width: 40%;
  }
  .journey_700 {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .journey-overlay {
      width: 40%;
      border-radius: 0px 0px 10px 10px;
      align-self: start;
    }
    .journey_700 {
      display: none;
    }
  }
  @media screen and (max-width: 700px) {
    .journey-overlay {
      display: none;
    }
    .journey_700 {
      color: #0041e8;
      display: block;
      font-size: 18px;
      padding-top: 10px;
    }
  }
}
