.services-page {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: white;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #0041e8;
    align-self: center;
    text-align: center;
    margin-bottom: 100px;
    margin-top: 50px;
  }

  .services-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 80px;
    width: 90%;

    .service-card {
      display: flex;
      justify-content: space-around;
      background-color: #fff;
      border-radius: 15px;
      width: 100%;
      margin-bottom: 40px;

      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 25%;

        .service-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
          border-radius: 10px;
          transform: rotate(5deg);

          &:first-of-type {
            opacity: 0.6; /* Adjust the opacity */
            filter: brightness(0.6); /* Adjust brightness for overlay effect */
          }

          &.overlapped {
            width: 100%;
            position: absolute;
            top: 5px;
            left: -20px;
            transform: rotate(-10deg);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .service-content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        border: 2px solid #86a3e9bd;
        border-radius: 10px;

        .service-category {
          font-size: 0.9rem;
          color: #999;
          margin-bottom: 5px;
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #1a202c;
          margin-bottom: 10px;
        }

        p {
          font-size: 1rem;
          color: #606060;
          margin-bottom: 20px;
          line-height: 30px;
        }

        .learn-more-button {
          align-self: start;
          background-color: #0041e8;
          color: #ffffff;
          padding: 8px 20px;
          font-size: 15px;
          border-radius: 5px;
          text-decoration: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
          border: 1px solid #0041e8;

          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }

    .service-card:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .collab-container {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 60px;
    width: 60%;
    padding: 90px 20px;

    .collab-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      border-radius: 10px;
      border: 2px solid black;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      width: 80%;

      .collab-image {
        width: 100%;
        max-width: 400px;

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }

      .collab-text {
        padding: 20px;
        text-align: center;

        h2 {
          font-size: 1.5rem;
          color: #1a202c;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1rem;
          color: #666;
          margin-bottom: 1.5rem;
        }

        button {
          padding: 10px 20px;
          font-size: 1rem;
          color: black;
          background-color: #ffcc00;
          border: 1px solid black;
          border-radius: 20px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #e6b800;
          }
        }
      }
    }

    @media (min-width: 768px) {
      .collab-content {
        flex-direction: row;
        padding: 40px;

        .collab-image {
          max-width: 50%;
        }

        .collab-text {
          width: 50%;
          text-align: left;
        }
      }
    }
  }

  // Modal Styles
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 80%;
    max-height: 90%;
    overflow: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2.5rem;
    cursor: pointer;
    background: none;
    border: none;
    color: #fff;
    z-index: 1100;
    transition: color 0.3s ease;
  
    &:hover {
      color: #e63946;
    }
  }
  
  .modal-image {
    width: 100%;
    max-height: 50vh;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content {
    text-align: center;
    padding: 20px;
  
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #1a202c;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 16px;
      color: #333;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }
}

// Media Queries for Responsiveness
@media (max-width: 1024px) {
  .services-container {
    width: 100%;

    .service-card {
      flex-direction: column !important;
      align-items: center;
      text-align: center;
      margin-bottom: 20px !important;

      .image-wrapper {
        width: 80% !important;
        margin-bottom: 20px;
      }

      .service-content {
        width: 90% !important;
        align-items: center !important;
        margin-top: 80px;

        .learn-more-button {
          align-self: center !important;
        }
      }
    }
  }
  
  .collab-container {
    width: 100% !important;
    padding: 40px 20px;

    .collab-content {
      width: 100%;
      padding: 20px;

      .collab-image {
        max-width: 100%;
        margin-bottom: 20px;
      }

      .collab-text {
        text-align: center;
        padding: 0;
      }
    }
  }
}

@media(max-width: 900px){
  .services-page .services-container .service-card .service-content  {
    margin-top: 35px;
  }
}

.collaboration-cta {
    display: flex;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    background-color: #0041e8;

    .cta-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    }

    .cta-image {
    width: 100%;
    max-width: 400px;

    /* Adjust max-width for smaller screens */
    img {
      width: 100%;
      max-width: 80%;
      height: auto;
      border-radius: 10px;
    }
  }

    .cta-text {
    width: 100%;
    padding: 0;
    text-align: center;

    h2 {
      font-size: 28px;
      color: #1a202c;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      color: #1a202c;
      line-height: 1.6rem;
      margin-bottom: 1.5rem;
    }

    button {
      background-color: #0041e8;
      color: #ffffff;
      padding: 8px 20px;
      font-size: 15px;
      border-radius: 5px;
      text-decoration: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border: 1px solid #0041e8;
      // transition: background-color 0.3s ease;

      &:hover {
        transform: scaleY(1.1);
        background-color: #0056b3; // Darker yellow on hover
      }
    }
  }

    @media (min-width: 1024px) {
    // padding: 8rem;
    /* Restore padding for larger screens */

    .cta-content {
      flex-direction: row;
      /* Revert to row layout for larger screens */
      padding: 2rem;
    }

    .cta-image {
      max-width: 50%;

      /* Adjust image width for larger screens */
      img {
        max-width: 100%;
      }
    }

    .cta-text {
      width: 50%;
      /* Adjust text width for larger screens */
      padding: 0 2rem;
      text-align: left;
      /* Align text to left for larger screens */
    }
  }
}
// .modal-content {
//   // border:  2px solid #86a3e9bd;
// }
@media (max-width: 768px) {
  .services-page .collaboration-cta {
    padding: 2rem !important;

    .cta-image img {
      max-width: 100%;
      height: 230px;
      margin-bottom: 20px;
    } 
  }
}