.projects {
  background-color: white;
  padding: 0px 30px 30px 30px;
  // padding-bottom: 50px;
  padding: 0px 30px 30px 30px;
  // padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h2 {
    text-align: center;
    margin-bottom: 70px;
    font-size: 32px;
    font-weight: 600;
    color: #0041e8;
  }
  .h2-span {
     color: #1a202c;
  }

  .project-list {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      user-select: none;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
      width: 97%;

      .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;

        .slick-track {
          display: flex;
          align-items: center;
          justify-content: center;
          list-style: none;
          margin: 0;
          padding: 0;
          transition: transform 0.5s ease;
          gap: 30px;
          margin-right: 100px;

          .project {
            display: flex !important;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 2px solid #86a3e9bd;
            border-radius: 10px;
            width: 30%; /* Adjusted width for larger screens */
            margin: 10px 0;
            height: 100%;
            padding: 20px 5px 20px 5px;
            // padding-top: 20px;

            .project-title{
              text-align: center;
              align-self: center;
              color: #0041e8;
              font-size: 16px;
              font-weight: 600;
              margin: 17px 5px 15px 5px;
            }

            .project-img {
              width: 90%;
              object-fit:fill;
              border-radius: 5px;
              // height: auto;
              height: 200px; /* Fixed height for all images */
            }

            .project-details {
              background-color: #fff;
              border-radius: 0 0 10px 10px;
              width: 95%;
              min-height: 135px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;

              p {
                font-size: 16px;
                line-height: 1.6;
                color: #1a202c;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .project-list {
      .slick-slider {
        width: 100% !important;

        .slick-list {
          .slick-track {
            gap: 30px;
            margin-right: 0;
            width: 100%;

            .project {
              width: 100% !important; /* Adjusted width for smaller screens */
              overflow: hidden;
              margin-right: 30px;

              
            .project-title{
             font-size: 16px;
            }

              .project-img {
                height: 50%;
                border-radius: 5px;
                margin-top: 5px; 
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width: 1024px) {
  .projects {
    padding: 10px;
  }
}

.learn-more {
  font-size: 14px;
  font-weight: 500;
  color: #0041e8;
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  &:hover {
    transform: scaleX(1.1);
    text-decoration: underline;
  }
}
.learn-more-icon {
  font-size: 10px;
}
@media (max-width: 768px) {
  .projects .project-list .slick-slider .slick-list .slick-track {
    gap: 20px;
  }
}