.popup-overlay {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.popup-modal {
  position: relative;
  width: 90%;
  max-width: 1000px;
  max-height: 95vh;
  overflow-y: auto;
  border-radius: 5px;
  padding: 80px;
  box-shadow: 0 10px 30px rgba(85, 12, 12, 0.5);
  border: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 10;
  
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.1);
  }
}

/* Modal content container */
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Left-aligned */
  text-align: left;
}

/* Logo container */
.logo-container {
  margin-bottom: 24px;
  
  .logo {
    height: 60px;
    width: auto;
  }
}

.logo-container img{
  height: 60px;
  width: auto;  
}

/* Title and subtitle styling */
.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  background: linear-gradient(90deg, #ffffff 0%, rgba(238, 238, 241, 0.9254901961) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(183, 74, 74, 0.2);
  margin-top: 172px;
}

.modal-subtitle {
  font-size: 1rem;
  margin: 10px 0px 10px 0px;
  max-width: 100%;
  line-height: 1.8;
  flex-wrap: wrap;
}

/* Container for buttons in a row */
.button-row {
  display: flex;
  gap: 15px;
  margin-top: 16px;
  flex-wrap: wrap; /* Allow wrapping on very small screens */
}

/* Feature button styling */
.feature-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #0041e8;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative; 
  overflow: hidden;    
}

.feature-button:hover {
  transform: scale(1.05);
}

.feature-button-2 {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #0041e8;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;  
  overflow: hidden;    
}

.feature-button-2:hover {
  transform: scale(1.05);
}

.feature-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;  
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: horizontalShine 2s linear infinite;
}

@keyframes horizontalShine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}


/* Responsive styles for screens up to 768px wide */
@media (max-width: 768px) {
  .popup-modal {
    padding: 20px;
    width: 95%;
    max-width: 95%;
    background-size: contain;
  }
  
  .modal-title {
    font-size: 1.3rem;
    margin-top: 100px;  // Adjusted for smaller screen
  }
  
  .modal-subtitle {
    font-size: 1rem;
    margin: 10px 0px 10px 0px;
  }
  
  .button-row {
    flex-direction: row; /* Keep buttons in a row if possible */
    justify-content: flex-start;
    gap: 15px;
  }
  
  .feature-button {
    padding: 10px 16px;
    font-size: 0.9rem;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }
}

/* Further adjustments for very small screens (up to 480px wide) */
@media (max-width: 480px) {
  .popup-modal {
    padding: 15px;
  }
  
  .modal-title {
    font-size: 1.2rem;
    margin-top: 80px;
  }
  
  .modal-subtitle {
    font-size: 0.9rem;
  }
  
  .button-row {
    flex-direction: column; /* Stack buttons vertically */
    gap: 15px;
  }
  
  .feature-button {
    width: 100%;
    justify-content: center;
  }
  
  .close-button {
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
  }
}

/* Keyframe animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
