.aboutus-container {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: white;
  display: flex;
  flex-direction: column;

  .aboutus-hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
    height: 700px;
    overflow: hidden;
    background-color: white;

    .aboutus-hero-image-1,
    .aboutus-hero-image-2,
    .aboutus-hero-image-3 {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
    }

    .aboutus-hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
    }

    .image-overlay h2 {
      color: white;
      font-size: 32px;
      font-weight: 600;
      margin: 0;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
    @media(max-width: 768px) {
      .image-overlay h2 { 
        font-size: 28px; 
      }
    }
    .aboutus-hero-image-1 {
      width: 28%;
      // margin-left: 20px;
    }

    .aboutus-hero-secondary {
      display: flex;
      margin-left: 40px;
      flex-direction: column;
      width: 50%;
      gap: 40px;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transition: transform 0.6s;
    }

    .aboutus-hero-image-1:hover .flip-card-inner,
    .aboutus-hero-image-2:hover .flip-card-inner,
    .aboutus-hero-image-3:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .front,
    .back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      border-radius: 10px;
    }

    .front {
      transform: rotateY(0);
      background: rgba(
        0,
        0,
        0,
        0.6
      ); // Ensures the front side has a background overlay
    }

    .back {
      transform: rotateY(180deg);
      background-color: white;  
      border: 2px solid #86a3e9bd;
      color: #0041e8;  
      p {
        width: 80%;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .about-cards {
      width: 24rem;
      height: 36rem;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      color: white;
      box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
     
      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.9;
        transition: opacity .2s ease-out;
      }
      .card-overlay {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0px;
        opacity: 1;
        position: absolute;
        top: 0px;
        width: 100%;
        transition: opacity 0.3s;
       }
    
      h2 {
        position: absolute;
        inset: auto auto 30px 30px;
        margin: 0;
        transition: inset .3s .3s ease-out;
        font-weight: normal;
        text-transform: uppercase;
      }
      
      p, a {
        position: absolute;
        opacity: 0;
        max-width: 80%;
        transition: opacity .3s ease-out;
      }
      
      p {
        inset: auto auto 80px 30px;
      }
      
      a {
        inset: auto auto 40px 30px;
        color: inherit;
        text-decoration: none;
      }
      
      &:hover h2 {
        inset: auto auto 220px 30px;
        transition: inset .3s ease-out;
      }
      
      &:hover p, &:hover a {
        opacity: 2;
        transition: opacity .5s .1s ease-in;
      }
      
      &:hover img {
        transition: opacity .3s ease-in;
        opacity: 2;
      }
    
    }
    
    .material-symbols-outlined {
      vertical-align: middle;
    }
  }
  // Media Queries for Responsiveness
  @media (max-width: 1024px) {
    .aboutus-hero {
      flex-direction: column !important;
      padding: 0px 15px 0px 15px;
      // height: 900px;


      .aboutus-hero-image-1 {
        align-self: center;
        width: 100% !important;
        margin: 40px 0px 20px 0px;
        height: 33%;
      }

      .aboutus-hero-secondary {
        align-self: center;
        width: 100% !important;
        margin: 20px;
        height: 66%;
        gap: 20px;
        .aboutus-hero-image-2,
        .aboutus-hero-image-3 {
          width: 100% !important;
          margin-left: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  .aboutus-values {
    padding: 30px;
    background-color: white;

    .aboutus-values-heading {
      text-align: center;
      font-size: 36px;
      color: #0041e8;
    }

    .aboutus-values-list {
      display: flex;
      justify-content: center;
      gap: 80px;

      .aboutus-values-item {
        width: 300px; /* Adjust width as needed */
        height: 300px; /* Adjust height as needed */
        perspective: 1000px;

      .values-front {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            background-color: #ffe353;

            .aboutus-values-text {
              font-size: 1.5rem;
              color: #000;
              text-align: center;
            }
          }

          .values-back {
            transform: rotateY(180deg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        
      }
    }
  }

  // Media Queries for Responsiveness
  @media (max-width: 1024px) {
    .aboutus-values .aboutus-values-list {
      flex-direction: column !important;
      align-items: center !important;
      padding: 10px !important;
    }
  }

  .aboutus-founders {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 20px;
    width: 100%;

    .aboutus-founders-heading {
      font-size: 32px;
      font-weight: 600;
      color: #0041e8;
      margin: 70px 0px 70px 0px;
    }
    @media (max-width: 1024px) {
      .aboutus-founders-heading {
        font-size: 28px;
      }
    }

    .aboutus-founders-list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 40px;

      .aboutus-card {
        width: 100%;
        max-width: 400px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #eff8ff;
        border: 2px solid #86a3e9bd;

        &:hover {
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            transform: scaleX(1.05) scaleY(1.05);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .aboutus-card-inner {
          display: flex;
          flex-direction: column;

          .aboutus-card-image-img {
            width: 100%;
            border-radius: 5px;
            margin-bottom: 10px;
          }

          .aboutus-card-heading {
            font-size: 20px;
            color: #1a202c;
            text-align: center;
            margin: 10px 0px 10px 0px;
            font-weight: 600;
          }

          .aboutus-card-description {
            font-size: 16px;
            line-height: 1.4;
            color: #1a202c;
            margin-bottom: 10px;
          }

          .aboutus-card-role-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .aboutus-card-role-inner {
              display: flex;
              align-items: center;

              .aboutus-card-role {
                font-size: 18px;
                font-weight: 600;
                color:  #0041e8;
                margin-right: 10px;
              }

              .aboutus-card-arrow {
                height: 24px;
                width: 24px;
                color: #0041e8;
              }
            }

            .aboutus-card-linkedin {
              height: 40px;
              width: 40px;
              object-fit: cover;
              color: #0041e8;
            }
          }
        }
      }
    }
  }

  // Media Queries for Responsiveness
  @media (max-width: 1024px) {
    .aboutus-founders .aboutus-founders-list {
      flex-direction: row;
      padding: 0px 20px 0px 20px;
    }
  }
  @media (max-width: 768px) {
    .aboutus-founders .aboutus-founders-list {
      flex-direction: column;
    }
  }
  // Media Queries for Responsiveness
  @media (max-width: 1024px) {
    .aboutus-partners .aboutus-partners-list {
      flex-direction: column;
      gap: 20px;
    }
  }

  .aboutus-journey {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
    background-image: url("../assets/about-us/milestone_11zon.webp");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    color: #fff;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .aboutus-journey-inner {
      position: relative;
      z-index: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .aboutus-journey-heading {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 50px;
      text-align: center;
      color: white;
    }
    @media(max-width: 1024px) {
      .aboutus-journey-heading {
        font-size: 28px;
      }
    }

    .aboutus-journey-timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1000px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 100%;
        background-color: #fff;
        z-index: 1;
      }

      .aboutus-journey-step {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
        position: relative;
        z-index: 2;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        .aboutus-journey-image {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
          background-color: white;
          padding: 2px;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }
        }

        .aboutus-journey-text {
          width: 60%;
          padding: 20px;
          background-color: white;
          border-radius: 15px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          margin-left: 20px;
          margin-right: 20px;
          position: relative;
          border: 2px solid #86a3e9bd;

          &:nth-child(even) & {
            margin-left: 0;
            margin-right: 20px;
          }

          h3 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
            color: #0041e8;
          }

          p {
            font-size: 1rem;
            line-height: 1.6;
            color: #222;
          }
        }
      }
    }
  }

  // Media Queries for Responsiveness
  @media (max-width: 1024px) {
    .aboutus-journey .aboutus-journey-timeline {
      &::before {
        left: 50%;
        top: 0;
        height: 100%;
      }
    }
  }
}


.social-presence {
  text-align: center;
  margin-bottom: 50px;

  .aboutus-founders-heading {
    font-size: 32px;
    font-weight: 600;
    color: #0041e8;
    margin: 70px 0px 70px 0px;
  }
  @media(max-width: 1024px) {
    .aboutus-founders-heading {
      font-size: 28px;
    }
  }

  .aboutus-founders-list {
    display: flex;
    flex-wrap: wrap;  
    justify-content: center;  
    gap: 25px;
    
    iframe {
      width: 100%;
      max-width: 560px;
      height: 315px;  
      border-radius: 10px;
      border: none;

      @media (max-width: 768px) {
        max-width: 100%;  
        height: auto; 
        aspect-ratio: 16/9;  
      }
    }
  }

  .social-presence-image {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }

  /* Hide desktop image on mobile and show mobile image */
  .desktop-image {
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }

  /* Hide mobile image on larger screens and show on mobile */
  .mobile-image {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.social-presence-medias {

    .media-container {
      display: flex;
      flex-wrap: wrap;
      width: 77%;
    }

    @media(max-width: 768px) {
      .media-container {
        width: 90%;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .col-lg-6, .col-md-12 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 1024px) {
      .col-lg-6, .col-md-12 {
        flex-direction: row;
      }
      
    }

    .media-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      border: 2px solid #86a3e9bd;
      border-radius: 10px;

      &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        transform: scaleX(1.05) scaleY(1.05);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
    }

    .you_tube_card {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    .card-body {
      flex-grow: 1;
    }
    .button-design {
        background-color: white;
        color: #86a3e9bd;
        border: 2px solid #86a3e9bd;
        border-radius: 5px;
        padding: 4px 8px;
        font-size: 15px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        position: relative;
        margin: 0px 0px 10px 0px;

      &:hover {
        transform: scaleX(1.1);
        background-color: #0041e8;
      }
    }

    @media (max-width: 1485px) {
      .button-design {
        margin: 0px 0px 10px 0px !important;
      }
    }
}

.mission-card {
  position: relative;
  overflow: hidden;

  .mission-card-body {
    text-align: center;
    position: relative;

    .mission-card-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &:hover .mission-card-text {
    opacity: 1;
  }
}
.media-box {
  height: 300px;
  border: 2px solid #86a3e9bd;
  border-radius: 10px;
  display: flex;
}
@media (max-width: 1024px) {
  .media-box {
    height: 280px;
  } 
}