.footer {
  padding: 2rem;
  color: #fff;
  background-color: #0041e8;
  padding-top: 40px;


  .footer-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;

    .footer-section {
      min-width: 200px;
      margin-bottom: 1rem;

      &.contact-info {
        display: flex;
        flex-direction: column;
        .footer-logo {
          width: 100%;
          max-width: 150px; // Adjust as needed
          height: auto;
          margin-bottom: 1rem;
        }

        p {
          margin: 0.5rem 0;
          color: white;
        }

        h3 {
          margin: 0.5rem 0;
          color: white;
        }
      }

      h3 {
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 600;
      }

      p {
        margin: 0.5rem 0;
      }

      a {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .app-promotion {
      flex: 1 1 100%;

      p {
        margin-bottom: 1rem;
      }

      .download-button {
        background-color: #ffcc00; // Yellow button
        color: #000;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 1rem;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #e6b800; // Darker yellow on hover
        }
      }

      .social-media {
        display: flex;
        gap: 1rem;
        font-size: 1.5rem;

        a{
          cursor: pointer;
          transition: color 0.3s ease;
          color: white;
          font-size: 30px;

          &:hover {
            color: #ffcc00; // Yellow on hover
          }
        }

      }
    }
    .app-download {
      width: 50%;
      max-width: 200px;
      height: auto;
      margin-top: -35px;

    }
  }

  @media (min-width: 1024px) {
    .footer-container {
      justify-content: space-between;

      .footer-section {
        flex: 1 1 auto;
        max-width: calc(25% - 2rem); // Adjust margin and width as needed
      }

      .app-promotion {
        flex: 1 1 40%;
      }
    }
  }

  .copyrightArea {
    text-align: center;
  }

  @media (max-width: 540px) {
    .footer-container .app-download {
      max-width: 150px;
      
    }
  }
}
