.aboutus-partners {
    width: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .aboutus-partners-heading {
      font-size: 32px;
      font-weight: 600;
      color:  #0041e8 ;
      margin: 70px 0px 70px 0px;
      text-align: center;
    }
    @media (max-width: 1024px) {
      .aboutus-partners-heading {
        font-size: 28px;
        margin: 35px 0px 70px 0px;
      }
    }

    .carousel-wrapper {
        width: 100%;
        overflow: hidden;
        border-radius: 10px;
    
        img {
          max-width: 100%;
          height:150px; 
          padding: 20px;
          border: 2px solid rgba(134, 163, 233, 0.7411764706);
          border-radius: 10px;
          margin: 0 10px;
        }
      }
      @media (max-width: 1024px) {
        // width: 88%;  
        // padding: 10px;  
    
        span {
          font-size: 28px;  
          margin-bottom: 20px;  
        }
    
        .carousel-wrapper {
          img {
            max-width: 100%;
            height: 115px; 
            gap: 20px;
          }
        }
      }
  }