.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 30px;
  background-color: #ffffff; /* Keep header white */
  top: 0;
  z-index: 1000;
  position: sticky;
  border: 1px solid #f0f0f0;
  box-shadow: #eeecec 0px 2px 5px;
  margin-bottom: 40px;

  .logo {
    padding: 20px;
    width: 14vw;
    
    img {
      width: 85%;
    }
  }

  .navigation {
    display: flex;
    justify-content: center;
    gap: 60px;
  
    a {
      text-decoration: none;
      color: black;
      font-weight: 600;
      font-size: 20px;
      transition: background-color 0.3s ease;
      text-shadow: 2px 2px #eeecec;
  
      &:hover,
      &:active {
        border-bottom: 3px solid black;
      }
  
      &.active {
        border-bottom: 3px solid black;
      }
    }
  
    @media (max-width: 1024px) {
      display: none;
    }
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 15px;

    a {
      text-decoration: none;
      padding: 8px 20px;
      border-radius: 5px;
      font-size: 15px;
      cursor: pointer;
      transition: background 0.3s ease;
      border: 1px solid transparent;
    }

    .contact-button {
      background-color: #0041e8;
      color: #ffffff;
      border: 1px solid #0041e8;
  
      &:hover {
        transform: scaleX(1.1);
        background-color: #0056b3;
      }
    }

    .fellowship-button {
      position: relative;
      overflow: hidden;
      background-color: #0041e8;
      color: #ffffff;
      border: 1px solid #0041e8;
    }
    .fellowship-button::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%; 
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: horizontalShine 2s linear infinite;
    }
    @keyframes horizontalShine {
      0% {
        left: -100%;
      }
      100% {
        left: 100%;
      }
    }
  
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
  
    @media (max-width: 1024px) {
      display: block;
    }
  }
  
  @media (max-width: 1024px) {
    .logo {
      width: 20vw !important;
    }
  }
  
  @media (max-width: 900px) {
    .logo {
      width: 35vw !important;
    }
  }
}

@media (max-width: 900px) {
  .header {
    margin-bottom: 0px !important;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  /* Apply a gradient only for the sidebar */
  background: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1500;
  transform: translateX(-100%);
  transition: transform 0.3s ease;

  &.open {
    transform: translateX(0);
  }

  .sidebar-content {
    display: flex;
    flex-direction: column;
    padding: 20px;

    a {
      text-decoration: none;
      color: black;
      font-weight: 500;
      padding: 10px 0;
      font-size: 18px;
      transition: background-color 0.3s ease;

      &:hover,
      &:active {
        background-color: #f0f0f0;
        text-decoration: underline;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media(max-width: 768px){
  .header .logo img {
      width: 114%;
  }
}
