.awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 100%;
  padding-bottom: 50px;

  h2 {
    font-size: 32px;
    margin: 70px 0px;
    font-weight: 600;
    color: #0041e8;
    text-align: center;
  }
  .h2-highlight {
    color: #1a202c;
  }
  h3 {
    font-size: 18px;
    margin-top: 20px;
  }

  .swiper {
    width: 100%;

    .swiper-slide {
      .carousel-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 500px;
          height: 300px;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease-in-out; /* Add transition for scaling effect */
        }

        .award-info {
          margin-top: 0.5rem;
          text-align: center;

          p {
            font-size: 1rem;
            color: #666;
            margin-bottom: 0.5rem;
          }
        }
      }

      &-active {
        transform: scale(2.2); /* Make the middle item bigger */
        z-index: 1;
      }
    }
  }

  // Responsive styles for small devices
  @media (max-width: 1024px) {
    h2 {
      font-size: 32px;
      margin-bottom: 70px;
    }
    .swiper {
      width: 90%;
      .swiper-slide {
        .carousel-container {
          img {
            width: 100%;
            height: auto;
          }

          .award-info {
            margin-top: 0.5rem;

            p {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
