
.community {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 32px;
    font-weight: 600;
    margin: 5px 0px 70px 0px;
    color:  #0041e8;
    text-align: center;
    width: 100%;
  } 
  .h2-highlight {
    color:  #1a202c;
  }

  .carousel-wrapper {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;

    img {
      max-width: 100%;
      height:150px;  
      padding: 20px;
      border: 2px solid rgba(134, 163, 233, 0.7411764706);
      border-radius: 10px;
      margin: 0 10px;
    }
  }


  @media (max-width: 1024px) {
    // width: 88%;  
    // padding: 10px;  

    span {
      font-size: 28px;  
      margin-bottom: 20px;  
    }

    .carousel-wrapper {
      img {
        max-width: 100%;
        height: 115px; 
        gap: 20px;
      }
    }
  }
}