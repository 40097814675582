.testimonials {
  background-color: white;
  // padding: 50px 0;

  .container-full {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }

  .title {
    font-size: 32px;
    color: #0041e8;
    margin: 20px 0px 70px 0px;
    font-weight: 600;
  }

  .button-group {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    span {
          margin: 10px;
          cursor: pointer;
          border-bottom: 2px solid #c9d8fcbd;
          padding: 5px;
          border-radius: 8px;
          background-color: white;
          color: #666b78bd;
          font-weight: 500;
          font-size: 20px;
          transition: color 0.3s ease;

      &.active {
        color: #1a202c;
        border-bottom: 4px solid rgba(134, 163, 233, 0.7411764706);
      }

      &:hover {
        background-color: #c9d8fcbd;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .slider-container {
      width: 100%;
      background-color: white;
      border-radius: 10px; 
      padding: 30px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);  
      margin-bottom: 20px;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 0.3s ease; /* Smooth transition for hover effect */

      &:hover {
        transform: translateY(-5px); /* Lift card on hover for a subtle effect */
      }

      .slider-card .card-box {
        display: flex;

        .image {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          border: 2px solid #0041e8;
          overflow: hidden;
          margin: 30px 0px 0px 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

          img {
            width: auto;
            height: 100%; /* Maintain aspect ratio */
            object-fit: cover;
          }
        }

        .content-row {
          padding-left: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 70%;

          .details {
            .name {
              font-size: 25px;
              font-weight: 600;
              color: #1a202c;
              margin-bottom: 10px;
            }
          }

          .content {
            font-size: 16px;
            color: #1a202c;
            line-height: 25px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .navigation-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .pagination-nav {
        display: flex;
        justify-content: center;
        margin-right: 20px;

        a {
          display: inline-block;
          margin: 0 5px;
          padding: 5px 10px;
          color: #007bff;
          background: #e9ecef;
          border-radius: 4px;
          transition: background 0.3s;
          text-decoration: none;

          &.active,
          &:hover {
            background: #0041e8;
            color: #fff;
            text-decoration: none;
          }
        }
      }

      .next-story-button {
        width: 50%;
        align-self: flex-end;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 400;
        color: #0041e8;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
          // border: 2px solid #0041e8;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  @media (max-width: 1024px) {
  
    .button-group {
      width: 80%;
      margin-left: 30px;
    }
    
    .title {
      font-size: 28px !important;
  
    }
    .content {
  
      .slider-container {
        width: 90% !important;
  
        .slider-card .card-box {
          display: flex;
          flex-direction: column !important;
          align-items: center;
  
          .image {
            width: 130px;
            height: 130px;
            // margin-bottom: 20px;
            margin: 0px 0px 15px 0px;
  
            img {
              width: 100%;
              object-fit: cover;
            }
          }
  
          .content-row {
            padding-left: 0px !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100% !important;
  
            .details {

              .name {
                font-size: 24px;
                font-weight: 600;
                color: #333;
                margin-bottom: 10px;
              }
            }
  
            .content {
              font-size: 16px;
              color: #555;
              line-height: 1.5;
              margin-bottom: 20px;
            }
          }
        }
      }

    }

    // .next-story-button {
    //   margin-top: 20px;
    // }

    /* Differentiate sections for small devices */
    .students .slider-container {
      background-color: #f9fafb; /* Light blue background for students section */
    }

    .teachers .slider-container {
      background-color: #f9f9f9; /* Light gray background for teachers section */
    }

    .parents .slider-container {
      background-color: #fdf1f1; /* Light pink background for parents section */
    }
  }
  @media (max-width: 540px) {
    .button-group span{
      font-size: 16px !important;
    }
    .title {
      margin: 20px 0px 70px 0px;
    }
    .content .slider-container .slider-card .card-box .content-row .details .name, p {
      text-align: center !important;
    }

  }
  @media (max-width: 540px) {
     .container-full {
      padding: 0 5px;
     }
  }
}
