.success-story {
  position: relative;
  align-self: center;
  width: 80%;
  height: 400px;
  background-size: cover;
  background-position: center;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  // margin-bottom: 80px;
  // margin-top: 20px;
  transition: background-image 0.5s ease-in-out;

  .story-content {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .student-name {
      width: 20%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .child-image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        margin: 20px 0px 20px 0px;
        border: 2px solid rgba(201, 216, 252, 0.7411764706);
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-left: 20px;
        color: white;
        align-self: center;
        text-align: center;
      }
    }

    .story {
      width: 70%;
      padding: 20px;
      align-self: center;

      p {
        font-size: 18px;
        line-height: 35px;
        text-align: left;
        font-weight: 400;
      }
    }

    .next-story-button {
      width: 35%;
      margin: 0px 15px 15px 0px;
      align-self: flex-end;
      padding: 8px 8px;
      border-radius: 8px;
      font-size: 35px;
      font-weight: 400;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
        // border: 2px solid white;
      }

      &:focus {
        outline: none;
      }
    }
  }

  // Responsive styles for small devices
  @media (max-width: 1024px) {
    width: 90%;
    height: auto;

    .story-content {
      width: 100% !important;
      flex-direction: column !important;
      align-items: center !important;

      .student-name {
        width: 90%;
        padding: 10px;
        text-align: center;
      }

      .story {
        width: 90%;
        padding: 10px;
      }

      .next-story-button {
        width: 30%;
        margin: 10px;
        padding: 8px 8px;
        font-size: 28px;
        align-self: center !important;
      }
    }
  }

  // fonts media query starts here
  @media (max-width: 540px) {
    .story-content .story p {
       text-align: center;
    }
  }
}
