.policy_section {
    margin: 5rem;

    @media (max-width: 1024px) {
        margin: 2rem;
    }

    h1 {
        color: #0041e8 ;
        font-size: 32px;
        margin-top: 0;
        margin-bottom: 20px;
    }
    
    .meta-info {
        background-color: #c6d3ff47;
        padding: 15px;
        border-left: 4px solid #0041e8 ;
        margin-bottom: 30px;
    }
    
    .meta-info p {
        margin: 5px 0;
        font-size: 0.9rem;
    }
    
    section {
        margin-bottom: 40px;
    }
    
    h2 {
        color: #0041e8 ;
        border-bottom: 2px solid #c6d3ff47;
        padding-bottom: 10px;
        margin-top: 30px;
    }
    
    h3 {
        margin-top: 25px;
        color: #444;
    }
    
    ul {
        padding-left: 20px;
    }
    
    li {
        margin-bottom: 12px;
    }
    
    .contact-info {
        background-color: #c6d3ff47;
        padding: 20px;
        border-radius: 8px;
        margin-top: 30px;
    }
    
    .highlight {
        color: #0041e8 ;
        font-weight: 600;
    }
    
    a {
        color: #0041e8 ;
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: underline;
    }
    
    @media (max-width: 768px) {
        body {
            padding: 15px;
        }
        
        h1 {
            font-size: 1.8rem;
        }
    }
}
