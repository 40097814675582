// contactus css
.contact-section {
    position: relative;
    width: 100%;
    padding-top: 100px;
    margin-top: -40px;
    padding-bottom: 100px;
    background: url('../assets/our-team.webp') no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .contact-container {
    position: relative;
    width: 90%;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form, .map {
    flex: 1;
    padding: 0px 0px 20px 0px;
    border-radius: 20px;
  }
  
  .contact-form {
    padding: 20px;
  }
  
  .contact-form h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color:  #1a202c;
    font-weight: 600;
  }

  @media (max-width: 768px) { 
    .contact-form h2 {
      font-size: 28px;
    }
  }

  
  .contact-form h2 span {
    color: #0041e8;
  }
  
  .contact-form p {
    margin-bottom: 20px;
    font-size: 16px;
    color:  #1a202c;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .contact-form input, .contact-form select, .contact-form button {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .contact-form textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    height: 100px;
  }
  
  
  .contact-form button {
    background-color: #0041e8;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color:  #0056b3;
    transform: scaleX(1.05);
    transition: 0.3s ease;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-between;
    color: #666;
  }
  a {
    color: #666;
    text-decoration: none;

    &:hover {
      color: #0041e8;
      text-decoration: underline;
    }
  }
  .map {
    position: relative;
    height: auto;
  }
  
  @media (max-width: 1024px) {
    .contact-container {
      flex-direction: column;
    }
  
    .map {
      height: 300px;
    }
  }
  .contact-section {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.02);
    }
  
    .contact-container {
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
  
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  
    .contact-form {
  
      .form-group {
        margin-bottom: 20px;
  
        input,
        select,
        textarea {
          width: 100%;
          padding: 12px;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 16px;
          
          &:focus {
            outline: none;
            border-color: #0041e8;
          }
  
          &:disabled {
            background: #f5f5f5;
            cursor: not-allowed;
          }
        }
  
        textarea {
          min-height: 150px;
          resize: vertical;
        }
  
        .text-danger {
          color: #dc3545;
          font-size: 14px;
          margin-top: 5px;
        }
      }
  
      button {
        width: 100%;
        background: #0041e8;
        color: white;
        padding: 15px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        transition: background 0.3s;
  
        &:hover {
          background: #003acc;
        }
  
        &:disabled {
          background: #ccc;
          cursor: not-allowed;
        }
  
        &.loading {
          position: relative;
          color: transparent;
  
          &::after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            margin: -10px 0 0 -10px;
            border: 2px solid white;
            border-top-color: transparent;
            border-radius: 50%;
            animation: loading 0.8s linear infinite;
          }
        }
      }
    }
  
    .contact-info {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px solid #ddd;
  
      p {
        margin-bottom: 15px;
        
        strong {
          color: #0041e8;
        }
  
        a {
          color: #666;
          text-decoration: none;
          
          &:hover {
            color: #0041e8;
          }
        }
      }
    }
  
    .map {
      height: 100%;
      min-height: 400px;
      border-radius: 10px;
      overflow: hidden;
      padding: 20px;
    }
  }
  
  @keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }